import missionController from "./mission_controller";
import { BaseComponent } from "../../../common/base-component.js";
import rewardIcon from "./mission_icons.js";
import { loadAnimation } from 'lottie-web/build/player/lottie_light.min.js'
import animationData from "./animations/completed_animation.json";

import { DT_M_BREAK } from "../../../common/helpers.js";

const PROGRESS_DURATION = 1000;

const style = `
@media (min-width: ${DT_M_BREAK}) {
    :host {
        flex-grow: 1;
    }
}

.mission-progress-bar {
    border-radius: 6.25rem 0 0 6.25rem;
    height: .5rem;
    background: white;
    display: flex;
    align-items: center;
    position: relative;
}
.progress-bar {
    border-radius: .25rem;
    height: .25rem;
    margin-left: .0625rem;
    width: 0;
    transition: width ${PROGRESS_DURATION}ms ease-in-out;
}
.numbers {
    position: relative;
    color: var(--color-fg);
    font-size: .75rem;
}

.mission-progress-bar-container {
    width: 16.5rem;
    position: relative;
    margin: 0 10%;
    text-align: center;
}
.mission-progress-bar-container hr {
     flex-basis: 100%; 
     height: 0; 
     margin: 0; 
     border: 0;
}
.mission-progress-bar-container::before {
    position: absolute;
    left: -1.5rem;
    top: -2.75rem;
    content: '';
    width: 1px;
    height: 4.375rem;
    background: #bababa;
}

.mission-progress-bar-container::after {
    position: absolute;
    right: -2.75rem;
    top: -2.75rem;
    content: '';
    width: 1px;
    height: 4.375rem;
    background: #bababa;
}
@media (max-width: ${DT_M_BREAK}) {
    .mission-progress-bar-container::before,
    .mission-progress-bar-container::after {
        display: none;
    }
}

@media (min-width: ${DT_M_BREAK}) {
    .mission-progress-bar-container {
        width: 13.75rem;
        top: 1.5rem;
    }
}
@media (min-width: ${DT_M_BREAK}) and (min-resolution: 2dppx) and (pointer: coarse) {
    .mission-progress-bar-container {
        margin: 0 4%;
        width: 12.75rem;
    }
}

#icon {
    position: absolute;
    right: -1.1rem;
    top: -0.85rem;
    height: 2.5rem;
    width: 2.5rem;
}
#stars {
    position: absolute;
    right: -5rem;
    top: -2.5rem;
    height: 10rem;
    width: 10rem;
}
#completed {
    position: absolute;
    right: -11rem;
    top: -3rem;
    height: 10rem;
    width: 20rem;
}
@media (max-width: ${DT_M_BREAK}) {
    .mission-progress-bar-container {
        justify-content: unset;
        margin: 0.75rem 0 0 0;
    }
}

path[fill="rgb(188,0,0)"] {
    fill: currentColor;      
}

.mission-prize-description-marquee {
    width: 11.65rem;
    height: 0.938rem;
    overflow: hidden;
    position: absolute;
    top: -2.25rem;
}

@media (max-width: ${DT_M_BREAK}) {
    .mission-prize-description-marquee {
        width: 14.45rem;
    }
}

.mission-prize-description {
    position: absolute;
    top: -0.2rem;
    font-size: 0.875rem;
    white-space: nowrap;
    will-change: transform;
}

.scroll-text {
    animation: scroll-text 12s linear infinite;
}

@keyframes scroll-text {
    20% {
        transform: translateX(0%);
    }
    60% {
        transform: translateX(calc(-100% + 11.65rem));
    }
    94% {
        transform: translateX(calc(-100% + 11.65rem));
    }
}

@media (max-width: ${DT_M_BREAK}) {
    @keyframes scroll-text {
        20% {
            transform: translateX(0%);
        }
        60% {
            transform: translateX(calc(-100% + 14.45rem));
        }
        94% {
            transform: translateX(calc(-100% + 14.45rem));
        }
    }
}
`

customElements.define(
    "mission-progress-bar",
    class extends BaseComponent {
        static observedAttributes = ['data-color', 'data-total', 'data-completed', 'data-icon'];

        constructor() {
            super(style);

            missionController.subscribe(`data-ready`, (missionData) => {
                let alias = this.dataset.alias;
                this.dataset.completed = missionData.getCompletedStepCountByAlias(alias);
                this.dataset.total = missionData.getTotalStepCountByAlias(alias);
                this.dataset.icon = JSON.stringify(missionData.getMissionRewardTypesByAlias(alias));
            });

            missionController.subscribe(`progress-increment`, ({ alias, latestCompletedCount } ) => {
                if(alias === this.dataset.alias) {
                    this.dataset.completed = latestCompletedCount;
                }
                setTimeout(() => {
                    missionController.publish('progress_feedback-complete');
                }, PROGRESS_DURATION)

            });

            missionController.subscribe(`progress-complete`, ({ alias } ) => {
                if(alias === this.dataset.alias) {
                    const completeAnimation = loadAnimation({
                        animationData: animationData,
                        container: this.shadowRoot.querySelector('.mission-progress-bar-container #stars'), // Required
                        renderer: "svg", // Required
                        loop: 2, // Optional
                        autoplay: true, // Optional
                        name: "anim_compl_mission", // Optional
                    });
                    completeAnimation.play('anim_compl_mission');
                    this.dataset.icon = JSON.stringify('check');
                }
            });
        }
        connectedCallback(){
            super.connectedCallback();

            this.numbersElement = this.shadowRoot.getElementById("numbers");
            this.progressElement = this.shadowRoot.getElementById("progress-bar");
            this.iconElement = this.shadowRoot.getElementById("icon");

            const scrollDescriptionText = () => {
                const descripton = this.shadowRoot.querySelector('.mission-prize-description');
                const descriptionWrap = this.shadowRoot.querySelector('.mission-prize-description-marquee');
                const descriptionWrapWidth = descriptionWrap.getBoundingClientRect().width;
                const descriptionWidth = descripton.getBoundingClientRect().width;
        
                // Check if elements are loaded and populated before setting the variable widths
                if (descriptionWrapWidth > 0 && descriptionWidth > 0) {
                    if (descriptionWrapWidth < descriptionWidth) {
                        descripton.classList.add('scroll-text');
                    }
                } else {
                    setTimeout(scrollDescriptionText, 1000); // Check again after 1 sec
                }
            };
        
            // Ensure the elements have rendered
            const ensureRenderComplete = () => {
                requestAnimationFrame(() => {
                    scrollDescriptionText();
                });
            };
        
            ensureRenderComplete();
        }

        attributeChangedCallback(name, oldValue, newValue) {
            if(!this.firstRender && oldValue !== newValue) {
                this.update(name.replace('data-', ''));
            }
        }

        render() {
            if(this.firstRender) {
                let icon = '';
                let numbers = '';

                if(this.dataset.completed && this.dataset.total) {
                    if(this.dataset.completed === this.dataset.total){
                        icon = rewardIcon('check', true);
                        numbers = 'Complete';
                    } else {
                        if(this.dataset.icon){
                            icon = rewardIcon(JSON.parse(this.dataset.icon), true);
                        }
                        numbers = `${this.dataset.completed}/${this.dataset.total}`;
                    }
                }

                return `
                <div class="mission-progress-bar-container">
                    <div class="mission-prize-description-marquee">
                        <div class="mission-prize-description">
                            <slot name="title">

                            </slot>
                        </div>
                    </div>
                    <div class="mission-progress-bar" id="mission-progress-bar" style="border: 2px solid ${this.dataset.color}">
                        <div class="progress-bar" role="progress-bar" id="progress-bar" style="background: ${this.dataset.color}; width: ${100 * this.dataset.completed/this.dataset.total}%;"></div>
                        
                    </div>
                    <div id="stars" style="color: ${this.dataset.color}"></div>
                    <div id="icon" style="color: ${this.dataset.color}">
                        ${icon}
                    </div>
                    <div class="numbers" id="numbers">
                        ${numbers}
                    </div>
                </div>
                `;
            }
        }

        update(datum) {
            if(datum === 'completed' || datum === 'total') {
                const pct = 100 * this.dataset.completed / this.dataset.total;
                if(!Number.isNaN(pct)) {
                    if(pct === 100) {
                        this.numbersElement.innerHTML = 'Complete';
                    } else {
                        this.numbersElement.innerHTML = `${this.dataset.completed}/${this.dataset.total}`;
                    }
                    this.progressElement.style.width = `${pct}%`;
                }
            }

            if(datum === 'icon') {
                const pct = 100 * this.dataset.completed / this.dataset.total;
                if(!Number.isNaN(pct)) {
                    if(pct === 100) {
                        this.iconElement.innerHTML = rewardIcon('check', true);
                    } else {
                        this.iconElement.innerHTML = rewardIcon(JSON.parse(this.dataset.icon), true);
                    }
                }
            }
        }
    },
);